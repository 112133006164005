const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let res: boolean;
  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    logger().debug('Fallback: Copying text command was ' + msg);
    res = successful;
  } catch (err) {
    logger().error('Fallback: Oops, unable to copy', err);
    res = false;
  }

  document.body.removeChild(textArea);
  return res;
};

export const copyTextToClipboard = async (text: string): Promise<boolean> => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return await navigator.clipboard.writeText(text).then(function() {
    logger().debug('Async: Copying to clipboard was successful!');
    return true;
  }, function(err) {
    logger().error('Async: Could not copy text: ', err);
    return false;
  });
};
